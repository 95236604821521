export const isBrowser = () => typeof window !== "undefined";

export const getLng = () => {
  return window.localStorage.getItem('lng');
}

export const getLink = (to) => {
  if (isBrowser()) {
    const lng = getLng();
    return `${lng === 'zh' ? '' : '/en'}${to}`
  }
  return to;
}

export const price = [
  {
    name: '免费数据',
    price: 0,
  },
  {
    name: '',
    price: 2000,
  },
  {
    name: '',
    price: 4000,
  }
];
