import lol from "./images/lol.svg"
import csgo from "./images/csgo.svg"
import dota2 from "./images/dota2.svg"
import aov from "./images/aov.svg"
import logo from "./images/web-logo.svg"
import i_csgo from "./images/icon_csgo.png"
import i_lol from "./images/icon_lol.png"
import i_dota2 from "./images/icon_dota.png"
import i_aov from "./images/icon_aov.png"
import hBg from "./images/bg.png"
import hlsj from "./images/hailiangshuju copy.svg"
import dyc from "./images/diyanchi.svg"
import sssj_i from "./images/shishishuju.svg"
import footLogo from "./images/logo-foot.svg"
import sina from "./images/icon_weibo.svg"
import qq from "./images/icon_qq.svg"
import mail from "./images/icon_email.svg"
import email from "./images/icon_email.png"
import logo_other from "./images/logo.svg"
import world_map from "./images/world-map.png"
import pay_select from "./images/pay-select.png"
import check from "./images/icon_select.svg"
import eye from "./images/icon_eye.png"
import eye_c from "./images/icon_eye_c.png"
import cart from "./images/cart.svg"
import select from "./images/select.svg"
import before from "./images/before-1.png"
import before1 from "./images/before-2.png"
import before2 from "./images/before-3.png"
import before3 from "./images/before-4.png"
import before4 from "./images/before-5.png"
import alipay from "./images/alipay.svg"
import wechat from "./images/wechat.png"
import ic1 from "./images/icon_select1.png"
import ic2 from "./images/icon_select2.png"
import ic3 from "./images/icon_select3.png"
import ic4 from "./images/icon_select4.png"
import order from "./images/icon_projects.png"
import tjsj from "./images/tjsj.png"
import icon_tjsj from "./images/icon_tj.png"
import dzsj from "./images/dzsj.png"
import icon_dzsj from "./images/icon_dzsj.png"
import sssc from "./images/sssc.png"
import icon_sssc from "./images/icon_sssc.png"
import sssj from "./images/sssj.png"
import icon_sssj from "./images/icon_sssj.png"
import password from "./images/icon_password.png"
import mobile from "./images/icon_phone.png"
import code from "./images/icon_pin.png"
import mid1 from "./images/mid1.png"
import mid2 from "./images/mid2.png"
import mid3 from "./images/mid3.png"
import mid4 from "./images/mid4.png"
import after1 from "./images/after1.png"
import after2 from "./images/after2.png"
import after3 from "./images/after3.png"
import correct from "./images/check.png"
import wrong from "./images/wrong.png"
import base1 from "./images/icon_lsxx.png"
import base2 from "./images/icon_bo.png"
import base3 from "./images/icon_zddy.png"
import base4 from "./images/icon_yxnr.png"
import stat1 from "./images/icon_zdtj.png"
import stat2 from "./images/icon_yxnr(1).png"
import stat3 from "./images/icon_yxnr(2).png"
import stat4 from "./images/icon_yxnr.png"
import real1 from "./images/icon_lsxx.png"
import real2 from "./images/icon_bo.png"
import real3 from "./images/icon_zddy.png"
import real4 from "./images/icon_sjtj.png"
import live1 from "./images/icon_lsxx.png"
import live2 from "./images/icon_medal.png"
import live3 from "./images/icon_tv.png"
import arrow_l from "./images/arrow-left.png"
import arrow_d from "./images/arrow.gif"
import dzsj_i from "./images/dzsj_icon.png"
import sssc_i from "./images/sssc_icon.png"
import no_delay_i from "./images/no_delay_i.png"
import sssj_ic from "./images/sssj_icon.png"
import tjsj_i from "./images/tjsj_icon.png"
import wycsj_i from "./images/wycsj_icon.png"
import menu from "./images/menu.png"
import menu_white from "./images/menu_white.png"
import play_video from "./images/play_video.png"
import owl_intro from "./video/owl_intro.mp4"
import buyStep1 from "./images/icon_buyStep1.png"
import buyStep2 from "./images/icon_buyStep2.png"
import buyStep3 from "./images/icon_buyStep3.png"
import buyStep4 from "./images/icon_buyStep4.png"
import buyStep5 from "./images/icon_buyStep5.png"
import icon_cfdj from "./images/icon_cfdj.png"
import icon_Ablos from "./images/icon_Ablos.png"
import icon_opendota from "./images/icon_opendota.png"
import icon_ggbet from "./images/icon_ggbet.png"
import icon_score from "./images/icon_score.png"
import icon_maxplus from "./images/icon_max+.png"
import icon_xhh from "./images/icon_xhh.png"
import icon_gosuGamers from "./images/icon_gosuGamers.png"
import icon_stratz from "./images/icon_stratz.png"
import icon_jwdj from "./images/icon_jwdj.png"

const constant = {
  active: "#708BFF",
  subTitle: "#3B416D",
  desc: "#8992BF",
  logoBorder: "#BCC9FF",
  productBg: "#F6F9FF",
  partnersBg: "#F6F9FF",
  spaceColor: "#979797",
  homeLogo: [lol, csgo, dota2, aov],
  Logo: [i_dota2, i_lol, i_csgo, i_aov],
  icSet: [ic1, ic2, ic3, ic4],
  features: [tjsj, dzsj, sssc, sssj],
  feature_icons: [icon_tjsj, icon_dzsj, icon_sssc, icon_sssj],
  before: [before, before1, before2, before3, before4],
  mid: [mid1, mid2, mid3, mid4],
  after: [after1, after2, after3],
  base: [base1, base2, base3, base4],
  stat: [stat1, stat2, stat3, stat4],
  real: [real1, real2, real3, real4],
  custom: [real1, real2, real3, real4],
  live: [live1, live2, live3],
  arrow: [arrow_d, arrow_l],
  priceIcon: [sssc_i, tjsj_i, sssj_ic, dzsj_i, wycsj_i],
  email,
  mobile,
  password,
  code,
  footLogo,
  hBg,
  logo,
  logo_other,
  hlsj,
  dyc,
  sssj_i,
  sssj,
  sina,
  qq,
  mail,
  world_map,
  pay_select,
  check,
  eye,
  eye_c,
  cart,
  select,
  alipay,
  wechat,
  order,
  correct,
  wrong,
  menu,
  menu_white,
  no_delay_i,
  owl_intro,
  play_video,
  buyStep: [buyStep1, buyStep2, buyStep3, buyStep4, buyStep5],
  partnersData: [
    { title: "超凡电竞", imgUrl: icon_cfdj },
    { title: "Ablos", imgUrl: icon_Ablos },
    { title: "OPENDOTA", imgUrl: icon_opendota },
    { title: "GGBET", imgUrl: icon_ggbet },
    { title: "Score", imgUrl: icon_score },
    { title: "MAX+", imgUrl: icon_maxplus },
    { title: "小黑盒", imgUrl: icon_xhh },
    { title: "GOSU GAMERS", imgUrl: icon_gosuGamers },
    { title: "STRATZ", imgUrl: icon_stratz },
    { title: "玩加电竞", imgUrl: icon_jwdj },
  ],
  purchaseStep: [
    { step1: buyStep1 },
    { step2: buyStep2 },
    { step3: buyStep3 },
    { step4: buyStep4 },
    { step5: buyStep5 },
  ],
}

export default constant
